import { IOverrideProductBlock, IProductBlock, IProductCurrency } from '@/interfaces'

export function isOverrideProductBlockCheck(
	value: IProductBlock | IOverrideProductBlock,
): value is IOverrideProductBlock {
	return !!(value as IOverrideProductBlock).product
}
export function getFullProduct(productBlock: IProductBlock | IOverrideProductBlock) {
	if (isOverrideProductBlockCheck(productBlock)) {
		const cleanProductBlock = Object.fromEntries<IOverrideProductBlock>(
			Object.entries(productBlock).filter(([key, value]) => value !== null),
		)
		return { ...productBlock, ...productBlock.product, ...cleanProductBlock }
	}
	return productBlock
}

export function getFullProductCurrency(
	productBlock: IProductBlock | IOverrideProductBlock,
	productCurrency: IProductCurrency,
) {
	if (isOverrideProductBlockCheck(productBlock)) {
		const cleanProductCurrency = Object.fromEntries<IProductCurrency>(
			Object.entries(productCurrency).filter(([key, value]) => value !== null),
		)
		const originalProductCurrency = productBlock.product.currencies.find(
			(value) => value.currency == productCurrency.currency,
		)
		if (originalProductCurrency !== undefined) {
			return { ...originalProductCurrency, ...cleanProductCurrency }
		} else if (!originalProductCurrency) {
			const missingCurrency = {
				id: undefined,
				currency: productCurrency.currency,
				price: 0.0,
				discount: 0,
				discountUnit: 'currency',
				vat: {
					id: 5,
					name: 'excl_high',
					country: 'nl',
					percentage: 21,
					including: false,
				},
			} as IProductCurrency

			return { ...missingCurrency, ...cleanProductCurrency }
		}

		return productCurrency
	}
	return productCurrency
}
