import { AugmentedActionContext, IState, Namespaced } from '@/store'
import { IComponentStoreState } from '@/store/modules/component/index'
import { ComponentMutations } from '@/store/modules/component/mutations'
import { ComponentGetters } from '@/store/modules/component/getters'
import { ActionTree } from 'vuex'
import { LoadComponentCommand } from '@/commands/Component/Commands/LoadComponentCommand'
import { ComponentManager } from '@/commands/Component/Managers/ComponentManager'
import { CreateBlockCommand } from '@/commands/Block/Commands/CreateBlockCommand'
import { Vue } from 'vue-property-decorator'
import { RemoveBlockCommand } from '@/commands/Block/Commands/RemoveBlockCommand'
import { ChangeBlockPositionCommand } from '@/commands/Block/Commands/ChangeBlockPositionCommand'
import { DuplicateBlockCommand } from '@/commands/Block/Commands/DuplicateBlockCommand'
import { DecoupleComponentBlockCommand } from '@/commands/Block/Commands/DecoupleComponentBlockCommand'
import { OffriHTTP } from '@/axiosRequestFunctions'
import { IBlock, ICategory, IComponent, IComponentBlock, IProduct, AllBlockTypes } from '@/interfaces'
import { UpdateComponentProperties } from '@/commands/Component/Commands/UpdateComponentProperties'
import { UpdateProductProperties } from '@/commands/Component/Commands/UpdateProductProperties'
import { UpdateComponentsBlockPropertiesCommand } from '@/commands/Component/Commands/UpdateComponentsBlockPropertiesCommand'
import { Languages } from '@/interfaces/languageTypes'
import { getEmptyTags, getTagsFromServer, transformIServerTagToITag } from '@/functions/tagFunctions'
import { AxiosResponse } from 'axios'
import { DuplicateComponentCommand } from '@/commands/Component/Commands/DuplicateComponentCommand'

export type ComponentActionContext = AugmentedActionContext<
	IComponentStoreState,
	ComponentMutations,
	ComponentGetters,
	ComponentActions
>

type C = ComponentActionContext

export interface ComponentActions {
	loadComponent(context: C, payload: { componentId: number }): Promise<void>
	loadAllCategories(context: C): Promise<void>
	loadComponentLanguageVersions(context: C): Promise<void>
	updateComponentProperties(
		context: C,
		payload: { component?: IComponent; newProperties: Partial<IComponent>; currentProperties?: Partial<IComponent> },
	): Promise<void>
	updateProductProperties(
		context: C,
		payload: {
			product?: IProduct
			component?: IComponent
			newProperties: Partial<IProduct>
			oldProperties?: Partial<IComponent>
		},
	): Promise<void>
	resetComponent(context: C): Promise<void>
	createBlock(context: C, payload: { blocks: Array<{ [key: string]: any }>; order?: number }): Promise<AllBlockTypes[]>
	removeBlock(injectee: C, payload: { order: number }): Promise<void>
	changeBlockPosition(
		injectee: C,
		payload: {
			currentBlockIndex: number
			newBlockIndex: number
			refs: { [key: string]: Vue | Vue[] | Element | HTMLElement }
		},
	): Promise<void>
	duplicateBlock(injectee: C, payload: { order: number }): Promise<void>
	decoupleComponentBlock(injectee: C, payload: { order: number }): Promise<void>
	updateBlockProperties(
		injectee: C,
		payload: {
			block: IBlock
			newProperties: Partial<IBlock>
			oldProperties?: Partial<IBlock>
			componentBlock?: IComponentBlock
		},
	): Promise<void>
	decoupleFromParentComponents(context: C, payload: number): Promise<void>
	decoupleFromTemplates(context: C, payload: number): Promise<void>
	checkCanBeProduct(context: C, payload: number): Promise<void>
	loadTags(context: C, payload: { language: Languages }): Promise<void>
	duplicateComponent(context: C, payload: { id: number; type: 'components' | 'products' }): Promise<AxiosResponse>
}

export type NamespacedComponentActions = Namespaced<ComponentActions, 'component'>

const actions: ActionTree<IComponentStoreState, IState> & ComponentActions = {
	async loadComponent(context, payload) {
		const command = new LoadComponentCommand(context, payload.componentId)
		await context.dispatch('execute', command, { root: true })
	},
	async loadAllCategories(context) {
		const workspace = context.rootGetters.workspace
		if (workspace !== null) {
			await OffriHTTP.get<ICategory[]>(`/workspaces/${workspace.id}/catalog/categories`).then((res) => {
				const categoriesObject = res.data.filter((category) => category.name !== null)
				context.commit('mutateCategories', categoriesObject)
			})
		} else {
			console.error('Error is loading categories: Workspace is null')
		}
	},
	async loadComponentLanguageVersions(context) {
		const component = context.state.component
		if (component !== undefined) {
			let languageVersions: IComponent[] = []
			if (component?.product) {
				let res = await OffriHTTP.get(
					`workspaces/${component.workspaceId}/catalog/components?product=${component?.product.id}`,
				)
				languageVersions = res.data
			} else {
				if (component) {
					languageVersions.push(component)
				}
			}
			context.commit('mutateComponentLanguageVersions', languageVersions)
		} else {
			console.error('loadComponentLanguageVersions was invoked while component was undefined')
		}
	},
	async updateComponentProperties(context, payload) {
		let component = payload.component !== undefined ? payload.component : context.state.component
		if (component !== undefined) {
			const command = new UpdateComponentProperties(
				context,
				component,
				payload.newProperties,
				payload.currentProperties,
			)
			await context.dispatch('execute', command, { root: true })
		} else {
			console.error('component is not defined. Component value is', component)
		}
	},
	async updateProductProperties(context, payload) {
		let product = payload.product !== undefined ? payload.product : context.state.component?.product
		let component = payload.component !== undefined ? payload.component : context.state.component
		if (product != null && component !== undefined) {
			const command = new UpdateProductProperties(
				context,
				component,
				product,
				payload.newProperties,
				payload.oldProperties,
			)
			await context.dispatch('execute', command, { root: true })
		} else {
			console.error(`Product is not defined. Product value is`, product)
		}
	},
	async resetComponent(context): Promise<void> {
		context.commit('mutateComponent', undefined)
		context.commit('mutateCategories', undefined)
		context.commit('mutateComponentLanguageVersions', undefined)
		context.commit('mutateTags', [])
	},
	async createBlock(context, payload) {
		const manager = new ComponentManager(context)
		const command = new CreateBlockCommand(manager, payload.blocks, payload.order)
		return await context.dispatch('execute', command, { root: true })
	},
	async removeBlock(context, payload) {
		const manager = new ComponentManager(context)
		const command = new RemoveBlockCommand(manager, payload.order)
		await context.dispatch('execute', command, { root: true })
	},
	async changeBlockPosition(context, payload) {
		const manager = new ComponentManager(context)
		const command = new ChangeBlockPositionCommand(
			manager,
			payload.currentBlockIndex,
			payload.newBlockIndex,
			payload.refs,
		)
		await context.dispatch('execute', command, { root: true })
	},
	async duplicateBlock(context, payload) {
		const manager = new ComponentManager(context)
		const command = new DuplicateBlockCommand(manager, payload.order)
		await context.dispatch('execute', command, { root: true })
	},
	async decoupleComponentBlock(context, payload) {
		const manager = new ComponentManager(context)
		const command = new DecoupleComponentBlockCommand(manager, payload.order)
		await context.dispatch('execute', command, { root: true })
	},
	async updateBlockProperties(injectee: ComponentActionContext, payload) {
		const command = new UpdateComponentsBlockPropertiesCommand(
			injectee,
			payload.block,
			payload.newProperties,
			payload.oldProperties,
			payload.componentBlock,
		)
		injectee.dispatch('execute', command, { root: true })
	},

	async decoupleFromParentComponents(context, payload) {
		const workspace = context.rootGetters.workspace
		if (workspace !== null) {
			await OffriHTTP.patch(`workspaces/${workspace.id}/catalog/components/${payload}/decouple-from-parents`)
		}
	},
	async decoupleFromTemplates(context, payload) {
		const workspace = context.rootGetters.workspace
		if (workspace !== null) {
			await OffriHTTP.patch(`workspaces/${workspace.id}/catalog/components/${payload}/decouple-from-templates`)
		}
	},

	async checkCanBeProduct(context, payload) {
		const workspace = context.rootGetters.workspace
		if (workspace !== null) {
			const res = await OffriHTTP.get(`workspaces/${workspace.id}/catalog/components/${payload}/check-can-be-product`)
			context.commit('mutateCanBeProduct', res.data)
		}
	},

	async loadTags(context, payload) {
		context.commit(
			'mutateTags',
			await getEmptyTags().then((res) => transformIServerTagToITag(res.data, payload.language)),
		)
	},
	async duplicateComponent(context, payload) {
		return context.dispatch('execute', new DuplicateComponentCommand(context, payload.id, payload.type), { root: true })
	},
}
export default actions
